

.book_detail_container {
    opacity: 0;
    padding-bottom: 1em;
}

.book_detail_infos {
    font-family: "Intro Regular";
    font-size: 15px;
    margin-block-start: 0.2em;
    margin-block-end: 0em;
    padding-left: 2em;
    padding-right: 2em;
    white-space: pre-wrap;
    line-height: 1.3em;
}

.text-red {
    color: var(--color-primary);
}

.book_detail_container button {
    font-family: "Intro Bold";
    cursor: pointer;
    font-size: 13px;
    color: var(--color-complementary-two);
    background-color: var(--color-yellow);
    margin-left: 4px;
    margin-right: 4px;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 2em;
    padding-right: 2em;
    border-color: var(--color-text-primary);
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 10px;
}

.book_detail_container :disabled {
    opacity: 0.5;
}

.book_detail_link {
    font-family: "Intro Regular";
    font-size: 12px;
    color: var(--color-complementary-two);
}
