.wrapper {
	padding-top: 20px;
	display: flex;
	justify-content: center;
}

.switch {
	position: relative;
	width: 60px;
	height: 20px;
	background-color: white;
	border-radius: 13px;
	cursor: pointer;
}
.ball {
	position: absolute;
	top: 3px;
	left: 5px;
	width: 15px;
	height: 15px;
	border-radius: 13px;
	background-color: var(--color-complementary-two);
}
@media (min-width: 768px) {
	span {
		font-size: initial;
		padding: 0 20px;
	}
}
.doubts {
	width: 30px;
	height: 30px;
	background-image: url('./../../assets/images/icon_ask.png');
	background-size: contain;
	background-repeat: no-repeat;
	cursor: pointer;
	margin: auto;
}
