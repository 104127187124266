.error404_container {
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    background-color: var(--color-complementary-four);
}

.error404_container img {
    width: 300px;
}