.info_buttons_container {
    padding-top: 30px;
    padding-bottom: 30px;
}

.game_manager_buttons {
    padding-bottom: 0px !important;
}

.info_buttons_wrap {
    width: var(--number-body-default-width);
    margin: auto;
    padding-top: 30px;
}

.info_buttons_container button {
    font-family: "Intro Bold";
    cursor: pointer;
    font-size: 17px;
    color: var(--color-complementary-two);
    background-color: var(--color-yellow);
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 4px;
    margin-right: 4px;
    padding-top: 12px;
    padding-bottom: 8px;
    width: 200px;
    border-color: var(--color-yellow);
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    text-align: center;
}

.game_manager_buttons button {
    font-family: "Intro Bold";
    cursor: pointer;
    font-size: 17px;
    color: var(--color-complementary-two);
    background-color: var(--color-yellow);
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 4px;
    margin-right: 4px;
    padding-top: 12px;
    padding-bottom: 8px;
    width: 200px;
    border-color: var(--color-yellow);
    border-radius: 50px;
    border-width: 1px;
    border-style: solid;
    text-align: center;
}

#close_game_manager {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
}

.card {
    height: 80%;
}