
.others_title_container {
    padding-top: 2em;
    padding-bottom: 1em;
    opacity: 0;
}

.others_title_container p {
    font-family: "Intro Bold";
    color: var(--color-yellow);
    font-size: 25px;
    margin-block-start: 0em;
    margin-block-end: 0em;
}