.auth_login_container {
  margin-top: 20px;
}

.auth_login_message {
  margin-top: 23px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 13px;
  color: var(--color-text-primary);
}

.auth_login_container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
}

.auth_login_container input {
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  color: var(--color-text-primary);
  background-color: #ffffff00;
  margin-top: 4px;
  margin-bottom: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-color: var(--color-text-primary);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  text-align: left;
  font-size: 14px;
  padding-left: 20px;
}

.auth_login_container input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-primary);
  opacity: 1; /* Firefox */
}

.auth_login_container input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-text-primary);
}

.auth_login_container input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-text-primary);
}

.checkbox-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 0;
}

.checkbox-wrapper p {
  font-size: initial;
  padding-left: 20px;
}
.checkbox-wrapper img {
  padding-left: 20px;
  width: 45px;
  height: 45px;
}

.checkbox {
  width: 8px;
  height: 8px;
  border: solid 7px white;
  border-radius: 20px;
  align-self: center;
  cursor: pointer;
}
